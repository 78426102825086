import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Parser from "html-react-parser";

function ModalText({ titre, text, textButton, small}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
            <>
                <button className={`theme-btn btn-style-six ${small}`}
                        onClick={handleShow}>
                    {textButton}
                </button>

                <Modal show={show} onHide={handleClose} backdrop={true} centered size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="shadow-title blue">{Parser(titre)}</div>
                        {text && (
                            <div className="sec-title text-center">
                                <div className="text">
                                    {Parser(text)}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </>
    );
}

export default ModalText;