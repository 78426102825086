import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormationSection from "../components/accueil/formations";

const FormationDataComponent = ({ container, containerIndex, nbrItem }) => {
    const [formationData, setFormationData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [loadingFormation, setLoadingFormation] = useState(false);
    const [pageFormationError, setPageFormationError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoadingFormation(true);
            try {
                const response = await axios.get( `${apiUrl}/front_formations?statut=1&itemsPerPage=${nbrItem}`);
                setFormationData(response.data);
                setLoadingFormation(true);
            } catch (error) {
                setPageFormationError(true);
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchData();
    }, [apiUrl, nbrItem]);

    return (
        <React.Fragment key={`banner-${containerIndex}`}>
        {loadingFormation ? (
            <FormationSection
                nbrItem={nbrItem}
                container={container}
                formationData={formationData}
                containerIndex={containerIndex}
            />
        ) : (
            <div className="loader_formation">
                <span className="loader__text">
                    {pageFormationError
                        ? 'une erreur est survenue veuillez réessayer ultérieurement'
                        : 'chargement des formations...'
                    }
                </span>
            </div>
        )}
        </React.Fragment>
    );
};

export default FormationDataComponent;