import {Link} from "react-router-dom";
import MobileSidebar from "./mobile-sidebar";
import React, {useState} from "react";

const MobileMenu = () => {

  const [showSidebar, setShowSidebar] = useState(false);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
      <header className="main-header main-header-mobile">
            <div className="auto-container">
              {/* <!-- Main box --> */}
              <div className="inner-box">
                <div className="nav-outer">
                  <div className="logo-box">
                    <div className="logo">
                      <Link to="/">
                        <img
                            src="/images/Logo-THF-web.png"
                            alt="Logo Talents Handicap formation"
                            width="263"
                            height="64"
                        />
                      </Link>
                    </div>
                  </div>
                  {/* End .logo-box */}

                  <MobileSidebar show={showSidebar} onClose={handleToggleSidebar} />
                  {/* <!-- Main Menu End--> */}
                </div>
                {/* End .nav-outer */}

                <div className="outer-box">
                  <button
                      className="mobile-nav-toggler"
                      onClick={handleToggleSidebar}
                  >
                    <img
                        width={32}
                        height={32}
                        src="/images/icons/icon-menu.svg"
                        alt='ico menu'
                    />
                    {/*<span className="flaticon-menu-1"></span>*/}
                  </button>
                  {/* right hamburger menu */}
                </div>
              </div>
            </div>
          </header>
  );
};

export default MobileMenu;
