import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {getSessions} from "../../lib/session";
import SessionFilterTab from "../../components/job-featured/SessionsFilterTab";
import axios from "axios";
import { Hero8, Libre, Main1, Main2 } from "../../components";
import FormationDataComponent from "../../data/FormationDataComponent";
import Parser from "html-react-parser";
import Ressources from "../../components/accueil/ressources";
import Seo from "../../components/common/Seo";
import Header from "../../components/home-7/Header";
import MobileMenu from "../../components/header/MobileMenu";

function FormationsPageAPi() {
    const { data: sessions } = useQuery({
        queryKey: ["sessions"],
        queryFn: getSessions,
    });

    const [sessionPageData, setSessionPageData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=15`);
                setSessionPageData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {!loading && (
                <>
                    <Seo pageTitle={sessionPageData[0]?.titre} pageDescription={sessionPageData[0]?.description} pageUrl={window.location.href} />

                    <span className="header-span"></span>

                    <Header />
                    <MobileMenu />
                    {sessionPageData?.map((sessionPage, sessionPageIndex) => (
                            <React.Fragment key={sessionPageIndex}>
                                {sessionPage?.frontPageContainers?.map((container, containerIndex) => (
                                    <div key={containerIndex}>
                                        {container?.banner && (
                                            <React.Fragment key={`banner-${containerIndex}`}>
                                                {container.frontPageContents?.map((content, contentIndex) => (
                                                    <Hero8 key={contentIndex} content={content} titleBreadcrumb={sessionPageData[0]?.titre}/>
                                                ))}
                                            </React.Fragment>
                                        )}

                                        {container?.libre && (
                                            <React.Fragment key={`libre-${containerIndex}`}>
                                                <Libre
                                                    background={container.background_color}
                                                    title={container.frontPageContents[0]?.titre}
                                                    description={container.frontPageContents[0]?.description}
                                                    index={container.frontPageContents[0]?.index}
                                                />
                                            </React.Fragment>
                                        )}
                                        {container?.formationsInterEntreprise && (
                                            <React.Fragment key={`formationsInterEntreprise-${containerIndex}`}>
                                                <section className="ls-section">
                                                    <div className="auto-container">
                                                        <div className="libre text-center">
                                                            {container.titre ? (
                                                                <h2 className="libre-title">
                                                                    {Parser(container.titre)}
                                                                </h2>) : ''}
                                                            {container.description ? (<div className="text mt-9">
                                                                {Parser(container.description)}
                                                            </div>) : ''}
                                                        </div>
                                                        <div className="row wow fadeInUp animated">
                                                            <SessionFilterTab sessions={sessions}/>
                                                        </div>
                                                    </div>
                                                </section>
                                            </React.Fragment>
                                        )}
                                        {container?.main1 && (
                                            <React.Fragment key={`main1-${containerIndex}`}>
                                                <Main1
                                                    background={container.background_color}
                                                    image={container.frontPageContents[0]?.image[0]}
                                                    title={container.frontPageContents[1]?.titre}
                                                    description={container.frontPageContents[1]?.description}
                                                    url={container.frontPageContents[1]?.url}
                                                    button={container.frontPageContents[1]?.button}
                                                />
                                            </React.Fragment>
                                        )}

                                        {container?.main2 && (
                                            <React.Fragment key={`main2-${containerIndex}`}>
                                                <Main2
                                                    background={container.background_color}
                                                    image={container.frontPageContents[1]?.image[0]}
                                                    title={container.frontPageContents[0]?.titre}
                                                    description={container.frontPageContents[0]?.description}
                                                    url={container.frontPageContents[0]?.url}
                                                    button={container.frontPageContents[0]?.button}
                                                />
                                            </React.Fragment>
                                        )}
                                        {container?.formation && (
                                            <FormationDataComponent
                                                nbrItem = '4'
                                                container={container}
                                                containerIndex={containerIndex}
                                            />
                                        )}
                                        {container?.ressources && (
                                            <React.Fragment key={`ressource-${containerIndex}`}>
                                                <Ressources
                                                    background={container.background_color}
                                                    title={container.titre}
                                                    description={container.description}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                ))}
                            </React.Fragment>
                    ))}
                </>
            )}
        </>
    );
}

export default FormationsPageAPi;
