import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Sidebar, Menu, MenuItem} from "react-pro-sidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarHeader from "./SidebarHeader";
import {isActiveLink, isActiveParentChild} from "../../../utils/linkActiveChecker";

import mobileMenuData from "../../../data/mobileMenuData";

const CollapsibleMenu = ({ category, router, onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const totalLink = category.items.length || 0;

    return (
        <>
            <li
                className={`custom-menu ${isOpen ? "custom-menu-open" : ""} ${isActiveParentChild(category.items, router.pathname) ? "custom-menu-active" : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {category.label}
                <span className={`ps-submenu-expand-icon ${isOpen ? "arrow-open" : ""}`}>
                    <span className="arrow"></span>
                </span>
            </li>
            <div className="custom-menu-content"
                 style={{
                     height: isOpen ? `${totalLink * 50}px` : "0px"
                 }}>
            {category.items.map((menuItem, i) => (
                    <MenuItem
                        key={i}
                        className={isActiveLink(menuItem.routePath, router.pathname) ? "menu-active-link" : ""}
                        component={<Link to={menuItem.routePath} />}
                        onClick={onClose}
                    >
                        {menuItem.name}
                    </MenuItem>
                ))}
            </div>
        </>
    );
};

const Index = ({show, onClose}) => {
    const router = useLocation();

    return (
        <div
            className={`offcanvas offcanvas-start mobile_menu-contnet ${show ? "show" : ""}`}
            tabIndex="-1"
            id="offcanvasMenu"
            data-bs-scroll="true"
        >
            <SidebarHeader onClose={onClose}/>
            {/* End pro-header */}

            <Sidebar>
                <Menu>
                    {mobileMenuData.map((category) => (
                        category.items && category.items.length > 0 ? (
                            <CollapsibleMenu
                                key={category.id}
                                category={category}
                                router={router}
                                onClose={onClose}
                            />
                        ) : (
                            <MenuItem
                                key={category.id}
                                className={
                                    isActiveParentChild(category.items, router.pathname)
                                        ? "menu-active-link"
                                        : ""
                                }
                                component={<Link to={category.routePath}/>}
                                onClick={onClose}
                            >
                                {category.icon ? (
                                    isActiveLink(category.routePath, router.pathname)
                                        ? <img src={category.iconActive} alt={category.label} width="20"/>
                                        : <img src={category.icon} alt={category.label} width="20"/>
                                ) : (
                                    category.label
                                )}
                            </MenuItem>
                        )
                    ))}
                </Menu>
            </Sidebar>
            <SidebarFooter/>
        </div>
    );
};

export default Index;
