import {Link} from "react-router-dom";
import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Social = () => {
  const socialContent = [
    { id: 1, name : "Facebook", src : "icon-fb.svg", icon: "fa-facebook-f", link: "https://www.facebook.com/talents.handicap" },
    { id: 2, name : "X", src : "icon-X.svg", icon: "fa-x-twitter", link: "https://x.com/TalentsHandicap" },
    { id: 3, name : "Instagram", src : "icon-instagram.svg", icon: "fa-instagram", link: "https://www.instagram.com/talents.handicap" },
    { id: 4, name : "Linkedin", src : "icon-in.svg", icon: "fa-linkedin-in", link: "https://www.linkedin.com/company/talents-handicap/mycompany/" },
  ];
  return (
    <>
      {socialContent.map((item) => (
        <Link
          to={item.link}
          target="_blank"
          key={item.id}
          aria-label={item.name}
        >
          <LazyLoadImage
              src={`/images/icons/${item.src}`}
              alt={item.name}
              effect="blur"
              width="25px"
              height="25px"
          />
        </Link>
      ))}
    </>
  );
};

export default Social;
