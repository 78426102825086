import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FooterContent4 = () => {
  const [show, setShow] = useState(false);
  const [currentFile, setCurrentFile] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const handleShow = (file, title) => {
    setCurrentFile(file);
    setModalTitle(title);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const footerContent = [
    {
      id: 1,
      title: "Qui sommes-nous ?",
      colClass: "col-lg-4",
      menuList: [
        { name: "Groupe Talents Handicap", route: "https://www.groupe-talentshandicap.com/", target: "_blank" },
        { name: "Forums en ligne Talents Handicap", route: "https://www.talents-handicap.com/", target: "_blank" },
        { name: "Nous contacter", route: "/contact" },
      ],
    },
    {
      id: 2,
      title: "Ressources utiles",
      colClass: "col-lg-4",
      menuList: [
        { name: "Nos solutions de sensibilisation", route: "/solutions-sensibilisation" },
        { name: "Démo Jeu de l'inclusion", route: "/start/2/talents-handicap" },
        // Utilisation de handleShow pour passer le fichier et le titre
        { name: "Conditions Générales de Vente", route: "#", action: () => handleShow("/docs/cgv_thf.pdf", "Conditions Générales de Vente") },
        { name: "Foire Aux Questions", route: "/resources/faq" },
        { name: "Plan du site", route: "/plan-site" },
      ],
    },
  ];

  return (
          <>
            {footerContent.map((item) => (
                    <div className={`footer-column ${item.colClass} col-md-6 col-sm-12`} key={item.id}>
                      <div className="footer-widget links-widget">
                        <h4 className="widget-title">{item.title}</h4>
                        <div className="widget-content">
                          <ul className="list">
                            {item?.menuList?.map((menu, i) => (
                                <li key={i}>
                                  {menu.action ? (
                                      <div onClick={menu.action} className="p-0 m-0 cgv">
                                        {menu.name}
                                      </div>
                                  ) : (
                                      <Link to={menu.route} {...(menu.target ? { target: menu.target } : {})}>
                                        {menu.name}
                                      </Link>
                                  )}
                                </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
            ))}

            <div className="footer-column col-lg-4 col-md-12 col-sm-12">
              <div className="footer-widget">
                <div className="widget-content">
                  <div className="newsletter-form">
                    <div className="text footer-qualiopi">
                      <LazyLoadImage
                          width={230} src="/images/logo-qualiopi-footer.webp"
                          alt="brand"
                          effect="blur"
                      />
                      <div>
                        La certification qualité a été délivrée au titre de la catégorie d’action suivante : <br />
                        <span>ACTIONS DE FORMATION</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Bootstrap pour afficher les fichiers */}
            <Modal show={show} onHide={handleClose} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {currentFile ? (
                        <iframe
                                src={currentFile}
                                title={modalTitle}
                                width="100%"
                                height="500px"
                        />
                ) : (
                        <div>Aucun document à afficher</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Fermer
                </Button>
              </Modal.Footer>
            </Modal>
          </>
  );
};

export default FooterContent4;
