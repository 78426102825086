import React, {useEffect, useState} from 'react';
import axios from "axios";
import Parser from "html-react-parser";
import JeuCard from "./JeuCard";

const JeuBlocks = ({entrepriseId, title, description}) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [jeux, setJeux] = useState([]);
    const [loadingJeux, setLoadingJeux] = useState(false);
    const [pageJeuxError, setPageJeuxError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/jeu_programmes?jeu_entreprise=${entrepriseId}&statut=1`);
                setJeux(response.data);
                setLoadingJeux(true);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
                setPageJeuxError(true);
            }
        };

        fetchData();
    }, [apiUrl, entrepriseId]);

    return (
        <section className="news section">
            <div className="auto-container">
                <div className="text-center libre ">
                    {title ? (
                        <h2 className="libre-title">
                            {Parser(title)}
                        </h2>) : ''}
                    {description ? (<div className="text mt-9">
                        {Parser(description)}
                    </div>) : ''}
                </div>

                <div className="sidebar-page-container">
                    <div className="auto-container">
                        <div className="row wow fadeInUp animated">
                            <div className="content-side col-lg-12 col-md-12 col-sm-12">
                                <div className="blog-grid">
                                    <div className="row justify-content-center">
                                        {loadingJeux ? (
                                            jeux && jeux.map((jeu, index) => (
                                                jeu.iframeJeu && jeu.iframeJeu.trim() !== '' && (
                                                    <JeuCard key={index} jeu={jeu}/>
                                                )
                                            ))
                                        ) : (
                                            <div className="loader_formation">
                                                <span className="loader__text">
                                                    {pageJeuxError
                                                        ? 'Une erreur est survenue, veuillez réessayer ultérieurement'
                                                        : 'Chargement des jeux...'
                                                    }
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default JeuBlocks;
