import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';

function ModalJeu({ jeuId, handleClose, show, newCode}) {
    const [code, setCode] = useState('');
    const [isCodeGenerated, setIsCodeGenerated] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const url = process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${jeuId}`;
    const generateCode = () => {
        const characters = '0123456789';
        let code = '';
        for (let i = 0; i < 5; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    }
    const handleGenerateCode = async () => {
        const newCode = generateCode();
        setCode(newCode);
        setIsCodeGenerated(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/jeu_entreprise_codes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: newCode,
                    jeu_entreprise: url,
                }),
            });

            if (!response.ok) {
                throw new Error('Une erreur s\'est produite lors de l\'enregistrement du code');
            }
            console.log('Code enregistré avec succès');
        } catch (error) {
            console.error('Erreur :', error);
        }
    }
    const handleCopyCode = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(() => {
                setIsCopied(true);
                console.log('Code copié dans le presse-papiers');
                setTimeout(() => setIsCopied(false), 5000);
            }).catch(err => {
                console.error('Erreur lors de la copie du code :', err);
            });
        }else{
            // Fallback method using execCommand
            const textArea = document.createElement('textarea');
            textArea.value = code;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setIsCopied(true);
                    console.log('Code copié dans le presse-papiers');
                    setTimeout(() => setIsCopied(false), 5000);
                } else {
                    console.error('Erreur lors de la copie du code');
                }
            } catch (err) {
                console.error('Erreur lors de la copie du code :', err);
            }

            document.body.removeChild(textArea);
        }
    };
    return (
            <>
                <Modal show={show} onHide={handleClose} backdrop={true} centered size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="shadow-title blue">Générer un code d’équipe</div>

                        <div className="sec-title text-center">
                            <div className="text">
                                Créez votre code d’équipe et partagez-le avec vos collègues pour commencer
                                l’aventure ensemble !
                            </div>
                            {!isCodeGenerated && newCode ? (
                                <button className="theme-btn btn-style-six mt-3 generate"
                                        onClick={handleGenerateCode}>
                                    Générer un code
                                </button>
                            ) : (
                                <>
                                    <div className="block-code position-relative">
                                        {code}
                                        <button className="position-absolute top-25 end-0 padding-right"
                                           onClick={handleCopyCode}>
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </div>

                                    {isCopied && <div className="copied-message">Code copié!</div>}
                                </>
                            )}
                        </div>

                    </Modal.Body>
                </Modal>
            </>
    );
}

export default ModalJeu;