import React, {useEffect, useState} from 'react';
import FooterDefault from "../footer/common-footer";
import Header from "./Header";
import MobileMenu from "../header/MobileMenu";
import axios from "axios";
import { Libre, Main1, Main2, Hero8 } from "../../components";
import Parser from "html-react-parser";
import FonctionnaliteCard from "../accueil/FonctionnaliteCard";
import Ressources from "../accueil/ressources";
import Partenaires from "../accueil/partenaires";
import FormationDataComponent from "../../data/FormationDataComponent";
import Testimonies from "../testimony/testimonies";
import Seo from "../common/Seo";

const Home7 = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [homeData, setHomeData] = useState([]);
    const [pageError, setPageError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/front_pages?type=1`);
                setHomeData(response.data);
                setIsPageLoaded(true);
            } catch (error) {
                setPageError(true);
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchData();
    }, [apiUrl]);

    return (
        <>
            {isPageLoaded ? (
                <>
                    <Seo
                        pageTitle={homeData[0]?.titre}
                        pageDescription={homeData[0]?.description}
                        pageUrl={window.location.href}
                        imageUrl="/images/Logo-THF-web.png"
                    />

                    <span className="header-span"></span>

                    <Header />

                    <MobileMenu />

                    {homeData?.map((home, homeIndex) => (
                                <React.Fragment key={homeIndex}>
                                    {home?.frontPageContainers?.map((container, containerIndex) => (
                                        <div key={containerIndex}>
                                            {container?.banner && (
                                                <React.Fragment key={`banner-${containerIndex}`}>
                                                    {container.frontPageContents?.map((content, contentIndex) => (
                                                        <Hero8 key={contentIndex} content={content} BreadcrumbVisible={false} homepage={true} />
                                                    ))}
                                                </React.Fragment>
                                            )}

                                            {container?.main1 && (
                                                <React.Fragment key={`main1-${containerIndex}`}>
                                                    <Main1
                                                        background={container.background_color}
                                                        image={container.frontPageContents[0]?.image[0]}
                                                        title={container.frontPageContents[1]?.titre}
                                                        description={container.frontPageContents[1]?.description}
                                                        url={container.frontPageContents[1]?.url}
                                                        video={container.frontPageContents[0]?.youtubeVideoId}
                                                        button={container.frontPageContents[1]?.button}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {container?.main2 && (
                                                <React.Fragment key={`main2-${containerIndex}`}>
                                                    <Main2
                                                        background={container.background_color}
                                                        image={container.frontPageContents[1]?.image[0]}
                                                        title={container.frontPageContents[0]?.titre}
                                                        description={container.frontPageContents[0]?.description}
                                                        url={container.frontPageContents[0]?.url}
                                                        video={container.frontPageContents[1]?.youtubeVideoId}
                                                        button={container.frontPageContents[0]?.button}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {container?.libre && (
                                                <React.Fragment key={`libre-${containerIndex}`}>
                                                    <Libre
                                                        background={container.background_color}
                                                        title={container.frontPageContents[0]?.titre}
                                                        description={container.frontPageContents[0]?.description}
                                                        index={container.frontPageContents[0]?.index}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {container?.partenaires && (
                                                <React.Fragment key={`partenaires-${containerIndex}`}>
                                                    <section className="clients-section">
                                                        <div className="text-center libre">
                                                            {container.titre ? (
                                                                <h2 className="libre-title">
                                                                    {Parser(container.titre)}
                                                                </h2>) : ''}
                                                            {container.description ? (<div className="text mt-9">
                                                                {Parser(container.description)}
                                                            </div>) : ''}
                                                        </div>
                                                        <div className="sponsors-outer mt-4" data-aos="fade">
                                                            <Partenaires partenaires={container.frontPageContents}/>
                                                        </div>
                                                    </section>
                                                </React.Fragment>
                                            )}

                                            {container?.fonctionnalite && (
                                                <React.Fragment key={`fonctionnalite-${containerIndex}`}>
                                                    <section className="layout-pt-20 layout-pb-40"
                                                             style={{ background: container.background_color }}>
                                                        <div className="auto-container">
                                                            <div className="text-center">
                                                                {container.titre ? (
                                                                    <h2 className="libre-title">
                                                                        {Parser(container.titre)}
                                                                    </h2>) : ''}
                                                                {container.description ? (<div className="text mt-9">
                                                                    {Parser(container.description)}
                                                                </div>) : ''}
                                                            </div>

                                                            <FonctionnaliteCard
                                                                fonctionnalites={container.frontPageContents}
                                                                containerIndex={containerIndex}
                                                            />
                                                        </div>
                                                    </section>
                                                </React.Fragment>
                                            )}

                                            {container?.formation && (
                                                <FormationDataComponent
                                                    nbrItem = '4'
                                                    container={container}
                                                    containerIndex={containerIndex}
                                                />
                                            )}

                                            {container?.testimony && Array.isArray(container.frontPageContents) && (
                                                <React.Fragment key={`testimony-${containerIndex}`}>
                                                    <Testimonies
                                                        background={container.background_image[0].urlS3}
                                                        index={container.frontPageContents?.index}
                                                        temoignages={container.frontPageContents}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {container?.ressources && (
                                                <React.Fragment key={`ressource-${containerIndex}`}>
                                                    <Ressources
                                                        background={container.background_color}
                                                        title={container.titre}
                                                        description={container.description}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}

                        <FooterDefault footerStyle="style-six alternate -type-11" />
                </>
            ) : (
                <div className="loader">
                    <div className="loader__logo">
                        <span className="loader__text">{
                            pageError
                            ? 'une erreur est survenue veuillez réessayer ultérieurement'
                            : 'chargement...'
                        }</span>
                    </div>
                </div>
            )}
        </>
      );
};

export default Home7;
