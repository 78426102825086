import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import HeaderNavContent from "../header/HeaderNavContent";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const handleClickGestion = () => {

    const currentUrl = window.location.href;

    // Rediriger vers le site Symfony avec l'URL en paramètre de requête
    window.location.href = process.env.REACT_APP_API_BASE_URL2+`?lastPage=${encodeURIComponent(currentUrl)}`;
  };

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    // <!-- Main Header-->
    <header
      className={`main-header header-shaddow -type-11 ${
        navbar ? "fixed-header animated slideInDown" : ""
      }`}
    >
        {/* <!-- Main box --> */}
        <div className="main-box">
          {/* <!--Nav Outer --> */}
          <div className="nav-outer">
            <div className="logo-box">
              <div className="logo">
                <Link to="/">
                  <img
                    src="/images/Logo-THF-web.png"
                    alt="Logo Talents Handicap formation"
                    width="263"
                    height="64"
                  />
                </Link>
              </div>
            </div>
            {/* End .logo-box */}

            <HeaderNavContent />
            {/* <!-- Main Menu End--> */}
          </div>
          <div className="outer-box">
            <div className="btn-box">
              <button className="theme-btn btn-style-four no-wrap"
                      onClick={handleClickGestion}>Formateurs / Apprenants</button>
              <Link
                  to="/demander-un-devis"
                  className="theme-btn btn-style-six no-wrap"
              >
                Demander un devis
                {/* <ContactModal textButton="Demander un devis" showText={false}/> */}
              </Link>
            </div>
          </div>
          {/* End .nav-outer */}
        </div>

    </header>
  );
};

export default Header;
