import React from 'react';
import {Helmet} from "react-helmet";

function Seo({ pageTitle, pageDescription, pageUrl, imageUrl }) {
    const removeBr = (html) => {
        return html.replace(/<br \/>/g, ' ');
    };
    return (
        <>
          <Helmet>
              <title>{pageTitle && removeBr(pageTitle)}</title>
              <meta name="description" content={pageDescription && removeBr(pageDescription)} />
              <meta name="keywords"
                    content="formation handicap, formation professionnelle handicap, formation inclusion, insertion professionnelle handicap, talents handicap formation, accessibilité emploi handicap, formation adaptée handicap, formation en ligne handicap, inclusion professionnelle, emploi et handicap"/>
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
              {/* Canonical URL */}
              <link rel="canonical" href={pageUrl}/>

              {/* Open Graph pour réseaux sociaux */}
              <meta property="og:title" content={pageTitle && removeBr(pageTitle)} />
              <meta property="og:description" content={pageDescription && removeBr(pageDescription)} />
              <meta property="og:url" content={pageUrl} />
              <meta property="og:image" content={imageUrl} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="fr_FR" />

              {/* Twitter Card */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={pageTitle && removeBr(pageTitle)} />
              <meta name="twitter:description" content={pageDescription && removeBr(pageDescription)} />
              <meta name="twitter:image" content={imageUrl} />
          </Helmet>
      </>
    );
}

export default Seo;
